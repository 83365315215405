<template>
  <div class="flex justify-between items-center">
    <span class="text-lg md:text-2xl font-semibold w-full">Procure Stock Scanner</span>

    <div class="flex w-20 justify-end text-right">
      <Menu as="div" class="relative inline-block text-left">
        <div>
          <MenuButton
              class="inline-flex w-full justify-center rounded-md bg-blue-900 hover:bg-blue-900/80 px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
          >
            <Bars3Icon class="h-6 w-6" />
          </MenuButton>
        </div>

        <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
        >
          <MenuItems
              class="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-black/5 focus:outline-none z-100"
          >
            <div class="p-2">
              <MenuItem v-slot="{ active }">
                <button
                    :disabled="!isOnline"
                    :class="[
										active ? 'bg-blue-900 text-white' : 'text-gray-900',
										'group flex w-full items-center rounded-md px-2 py-2 text-md',
										{ 'bg-gray-300 text-gray-500 cursor-not-allowed': !isOnline }
									]"
                    @click="showPostStocktakeModal"
                >
                  <ArrowsUpDownIcon
                      class="mr-2 h-6 w-6"
                      :class="{ 'text-gray-500': !isOnline, 'text-blue-900': isOnline }"
                      aria-hidden="true"
                  />
                  Post Stocktake
                </button>
              </MenuItem>
            </div>

            <div class="p-2">
              <MenuItem v-slot="{ active }">
                <button
                    :class="[
										active ? 'bg-blue-900 text-white' : 'text-gray-900',
										'group flex w-full items-center rounded-md px-2 py-2 text-md',
									]"
                    @click="navigateToSheets"
                >
                  <ArrowLeftIcon class="mr-2 h-6 w-6 text-blue-900" aria-hidden="true" />
                  Back to Sheets
                </button>
              </MenuItem>
            </div>

            <div class="p-2">
              <MenuItem v-slot="{ active }">
                <button
                    :class="[
										active ? 'bg-blue-900 text-white' : 'text-gray-900',
										'group flex w-full items-center rounded-md px-2 py-2 text-md',
									]"
                    @click="navigateToSettings"
                >
                  <AdjustmentsHorizontalIcon
                      class="mr-2 h-6 w-6 text-blue-900"
                      aria-hidden="true"
                  />
                  Device Settings
                </button>
              </MenuItem>
            </div>

            <div class="p-2">
              <MenuItem v-slot="{ active }">
                <button
                    :class="[
										active ? 'bg-blue-900 text-white' : 'text-gray-900',
										'group flex w-full items-center rounded-md p-2 text-md',
									]"
                    @click="logout"
                >
                  <ArrowLeftStartOnRectangleIcon
                      class="mr-2 h-6 w-6 text-blue-900"
                      aria-hidden="true"
                  />
                  Logout
                </button>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>
  </div>

  <ConfirmationDialog v-if="modalStore.showStocktakePostModal" />

  <!-- Successfully posted/synced sheets toast message -->
  <div
      id="syncToast"
      class="hidden fixed right-10 bottom-10 px-5 py-4 border-r-8 border-green-500 bg-white drop-shadow-lg"
  >
    <p class="text-sm">
      <span class="mr-2 inline-block px-3 py-1 rounded-full bg-green-500 text-white font-extrabold">i</span>
      Successfully posted/synced sheets(s).
    </p>
  </div>
</template>

<script setup>
import { onMounted, ref, onUnmounted } from "vue";
import { useStockTakeStore } from "@/stores/stockTakeStore";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { useRouter } from "vue-router";
import {
  ArrowsUpDownIcon,
  ArrowLeftStartOnRectangleIcon,
  AdjustmentsHorizontalIcon,
  ArrowLeftIcon,
} from "@heroicons/vue/24/outline";
import { Bars3Icon } from "@heroicons/vue/24/outline";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import { useModalStore } from "@/stores/modalStore";

const modalStore = useModalStore();
const router = useRouter();
const userId = ref(null);
const apiUrl = ref(process.env.VUE_APP_ADMIN_URL);
const isOnline = ref(navigator.onLine); // Reactive property to track online status
let intervalId;

// Function to check connectivity every 30 seconds
const checkConnectivity = async () => {
  try {
    const response = await fetch("https://jsonplaceholder.typicode.com/posts", { method: "HEAD" });
    isOnline.value = response.ok;
  } catch (error) {
    isOnline.value = false;
  }
};

onMounted(() => {
  handleUserId();
  intervalId = setInterval(checkConnectivity, 30000);
  window.addEventListener("online", () => (isOnline.value = true));
  window.addEventListener("offline", () => (isOnline.value = false));
});

onUnmounted(() => {
  // Clear the interval when the component is unmounted
  clearInterval(intervalId);
  window.removeEventListener("online", () => (isOnline.value = true));
  window.removeEventListener("offline", () => (isOnline.value = false));
});

async function handleUserId() {
  const urlParams = new URLSearchParams(window.location.search);

  // Check if neither exist
  if (!urlParams.has("user") && !localStorage.getItem("user")) {
    localStorage.removeItem("user");
    localStorage.removeItem("stockTakeSheets");
    localStorage.removeItem("products");
    window.location.href = apiUrl.value + "login";
    return;
  }

  // Check if both exist
  if (urlParams.has("user") && localStorage.getItem("user")) {
    if (urlParams.get("user") !== localStorage.getItem("user")) {
      localStorage.removeItem("user");
      localStorage.removeItem("stockTakeSheets");
      localStorage.removeItem("products");
      window.location.href = apiUrl.value + "login";
    }
  }

  // Get user ID value and assign to localStorage
  userId.value = urlParams.get("user") || localStorage.getItem("user");
  localStorage.setItem("user", userId.value);

  const stockTakeStore = useStockTakeStore();

  // Check for existing data in localStorage
  const hasStockTakeData =
      localStorage.getItem("stockTakeSheets") &&
      localStorage.getItem("stockTakeSheets") !== "" &&
      localStorage.getItem("stockTakeSheets") !== "[]" &&
      localStorage.getItem("products") &&
      localStorage.getItem("products") !== "" &&
      localStorage.getItem("products") !== "[]";

  if (hasStockTakeData) {
    try {
      stockTakeStore.stockTakeSheets = JSON.parse(
          localStorage.getItem("stockTakeSheets")
      );
      stockTakeStore.products = JSON.parse(localStorage.getItem("products"));
    } catch (error) {
      console.error("Error parsing localStorage data:", error);
    }
  } else {
    // No data in localStorage, fetch from database
    try {
      await stockTakeStore.fetchStockTakeSheets(userId.value);
    } catch (error) {
      console.error("Error fetching stock take data:", error);
      localStorage.removeItem("user");
      localStorage.removeItem("stockTakeSheets");
      localStorage.removeItem("products");
      window.location.href = apiUrl.value + "login";
    }
  }
}

const showPostStocktakeModal = () => {
  if (isOnline.value) {
    modalStore.showStocktakePostModal = true;
  }
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("stockTakeSheets");
  localStorage.removeItem("products");
  window.location.href = apiUrl.value + "login";
};

const navigateToSheets = () => {
  router.push({name: "Sheets"});
};

const navigateToSettings = () => {
  router.push({name: "Settings"});
};
</script>
