import { defineStore } from "pinia";
import axios from "axios";


export const useStockTakeStore = defineStore("stockTakeStore", {
  id: "stockTakeStore",
  state: () => ({
    stockTakeSheets: [],
    products: [],
    dataToSend: {},
    selectedCamera: "",
    stockTakeHistory: [],
  }),
  getters: {
    hasSavedData: (state) =>
      state.stockTakeSheets.length > 0 || state.products.length > 0,
    apiUrl: () => process.env.VUE_APP_ADMIN_URL,
  },
  actions: {
    async fetchStockTakeSheets(userId) {
      console.log("Fetching stock take sheets for user ID:", userId);

      //Remove existing stock take sheets and products
      localStorage.removeItem("stockTakeSheets");
      localStorage.removeItem("products");

      try {
        const response = await axios.get(
          this.apiUrl + "api/v1/get/stockTakeSheets/" + userId
        );
        this.stockTakeSheets = response.data.sheets;

        // console.log("Fetched stock take sheets:", this.stockTakeSheets);

        this.products = [];
        for (const sheet of this.stockTakeSheets) {
          console.log("Sheet: ", sheet);
          await this.fetchProducts(sheet.id);
        }
      } catch (error) {
        console.error("Error fetching stock take sheets:", error);
        // Handle errors appropriately
      }
      console.log("Done fetching stock take sheets and products");

      localStorage.setItem("stockTakeSheets", JSON.stringify(this.stockTakeSheets));
      localStorage.setItem("products", JSON.stringify(this.products));
      localStorage.setItem("stockTakeHistory", JSON.stringify(this.stockTakeHistory));
    },
    async fetchProducts(sheetId) {
      try {
        console.log(sheetId);
        const response = await axios.get(
          this.apiUrl + "api/v1/get/stockTakeProducts/" + sheetId
        );
        const fetchedProducts = response.data.products;
        this.products = this.products.concat(fetchedProducts);
        // console.log("Fetched products for sheet:", sheetId, fetchedProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
        // Handle errors appropriately
      }
    },

    //This method synchronizes the stockTakeStore back to the database
    async syncData(selectedSheets) {
      try {
        console.log("start sync");

        this.dataToSend = {
          stockTakeSheets: selectedSheets || this.stockTakeSheets,
          products: this.products,
        };

        console.log('dataToSend', this.dataToSend);
        
        const response = await axios.post(
          this.apiUrl + "api/v1/update/stockTakeData",
          this.dataToSend,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data);

        const userId = localStorage.getItem("user");
        this.fetchStockTakeSheets(userId);
        
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },

    hydrateCountHistory() {
      const stockTakeHistory = localStorage.getItem("stockTakeHistory");
      if (stockTakeHistory) {
        this.stockTakeHistory = JSON.parse(stockTakeHistory);
      }
    },

    updateStockTakeProduct(productId, newStatus, newQty) {
      const productIndex = this.products.findIndex(
        (product) => product.id === productId
      );

      if (productIndex !== -1) {
        this.products[productIndex].status = newStatus;

        this.addCount(productId, newQty);

        if (this.products[productIndex].qty === "Nothing") {
          this.products[productIndex].qty = Number(newQty);
        } else {
          this.products[productIndex].qty =
            Number(this.products[productIndex].qty) + Number(newQty);
        }
      } else {
        console.warn("Stock take product not found:", productId);
      }
    },

    addCount(productId, count) {
      const product = this.stockTakeHistory.find(p => p.productId === productId);
      if (product) {
        product.counts.push({ count, timestamp: new Date().toISOString() });
      } else {
        // If the product doesn't exist, create a new one
        this.stockTakeHistory.push({
          productId,
          productDescription: 'New Product',
          counts: [{ count, timestamp: new Date().toISOString() }],
        });
      }
    },

    resetCounts(productId) {
      const product = this.stockTakeHistory.find(p => p.productId === productId);
      if (product) {
        // Filter out the product with the specified productId
        this.stockTakeHistory = this.stockTakeHistory.filter(product => product.productId !== productId);
      }
    },

    // Add a new action to retrieve stock take history for a product
    getProductHistory(productId) {

      const productHistory = this.stockTakeHistory.find(
        (history) => history.productId === productId
      );

      return productHistory ? productHistory.counts : []; // Return an empty object if no history found
    },

    updateStockTakeSheetStatus(sheetId, newStatus) {
      const sheetIndex = this.stockTakeSheets.findIndex(
        (sheet) => sheet.id === Number(sheetId)
      );

      if (sheetIndex !== -1) {
        this.stockTakeSheets[sheetIndex].status = newStatus;
      } else {
        console.warn("Stock take sheet not found:", sheetId);
      }
    },

    setSelectedCamera(camera) {
      this.selectedCamera = camera;
    },
    
  },
});
