import { defineStore } from 'pinia';

export const useModalStore = defineStore('modalStore', {
  state: () => ({
    showStocktakePostModal: false,  // State to control confirmation modal visibility
  }),
  actions: {
    openStocktakePostModal() {
      this.showStocktakePostModal = true;
    },
    closeStocktakePostModal() {
      this.showStocktakePostModal = false;
    }
  }
});