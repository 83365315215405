<template>
  <div class="bg-blue-200 w-full h-full min-h-screen">
    <header class="flex justify-center w-full">
      <div v-if="!isOnline" class="bg-red-600 text-white text-center p-2 right-24 top-4 fixed z-50">
        Working in Offline Mode
      </div>

      <div v-if="isOnline" class="bg-green-600 text-white text-center p-2 right-24 top-4 fixed z-50">
        Working in Online Mode
      </div>

      <nav class="w-full bg-white shadow-md p-4">
        <UserIdAndDataHandler />
      </nav>
    </header>
    <router-view />

    <div id="loader-wheel" class="fixed top-0 left-0 w-full h-full z-50 justify-center items-center bg-gray-600 bg-opacity-70 hidden">
      <!-- <fingerprint-spinner
          :animation-duration="1500"
          :size="100"
          color="#3333ff"
        /> -->

        <flower-spinner
          :animation-duration="2500"
          :size="100"
          color="blue"
        />
      </div>
  </div>
</template>

<script setup>
import UserIdAndDataHandler from "./components/UserIdAndDataHandler.vue";
// import { FingerprintSpinner } from 'epic-spinners'
import { FlowerSpinner } from 'epic-spinners';
import {onMounted, onUnmounted, ref} from "vue";

const isOnline = ref(navigator.onLine);
let intervalId;

const checkConnectivity = async () => {
  try {
    // Attempt to fetch a small, lightweight request to check connectivity
    const response = await fetch("https://jsonplaceholder.typicode.com/posts", { method: "HEAD" });
    isOnline.value = response.ok; // Online if request succeeds
  } catch (error) {
    isOnline.value = false; // Offline if request fails
  }
};

onMounted(async () => {
  intervalId = setInterval(checkConnectivity, 30000);
  checkConnectivity(); // Run once on mount

  // Optional: watch for navigator's online/offline events
  window.addEventListener("online", () => { isOnline.value = true; });
  window.addEventListener("offline", () => { isOnline.value = false; });
});

onUnmounted(() => {
  // Clear the interval when the component is unmounted
  clearInterval(intervalId);
  // Remove event listeners
  window.removeEventListener("online", () => { isOnline.value = true; });
  window.removeEventListener("offline", () => { isOnline.value = false; });
});

</script>

<style>
nav {
  padding: 20px;
}
</style>
